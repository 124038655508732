import React from "react";
import HeadingSection from "../../components/HeadingSection/HeadingSection";
import ContactForm from "../../components/ContactUs/ContactFormTwo";

const WhoWeAreThree = (dark, id) => (
  <section className={"section" + (dark ? " section-dark" : "")}>
    <div className="container" >
      <div className="row" id='section1'>
        <HeadingSection title="ПРО НАС" tagline="Будь-яку проблему, як і захворювання, дешевше попередити, ніж лікувати і усувати! - Дар'я Рогова"/>
        <div style={{color:'black', marginLeft:'15px', marginRight:'15px'}}>
          <p style={{color:'black'}}>City Brokers – універсальний помічник в страховому та фінансову світі для клієнтів, які надають перевагу уникнути впливу ризиків і форс-мажорних витрат, пов'язаних із усуненням наслідків від таких ризиків.</p>
          <p style={{color:'black'}}>Сity Brokers – регіональна страхова та консалтингова агенція, яка надає послуги з ризик-аудиту та управління ризиками для юридичних осіб. Ми пропонуємо нашим клієнтам рішення у сфері страхування та перестрахування, забезпечуємо повний цикл обслуговування: аналіз потенційних загроз, розробка програм страхування, формування страхової ємності та супровід процесу врегулювання збитків, включаючи представництво в судах та інших державних органах.</p>
          <p style={{color:'black'}}>Нові послуги для наших клієнтів:</p>
          <ul>
            <li style={{listStyle:'none'}}>- Аудит ризиків IT систем та IT-безпеки підприємств;</li>
            <li style={{listStyle:'none'}}>- Формування планів пенсійного накопичення для працівників підприємств.</li>
          </ul>
          <p style={{color:'black'}}>Команда City Brokers постійно перебуває у пошуку нових передових рішень щодо управління ризиками для своїх клієнтів не лише на українському, а й на міжнародному ринках. На сьогоднішній день ми можемо запропонувати нашим клієнтам розміщення ризиків на ринку ЄС, Британії, США, Канади, ринках країн СНД, а також Середньої та Південно-Східної Азії.</p>
        </div>
      </div>
      <div className="row">
        <HeadingSection title="ОФОРМИТЬ СТРАХОВКУ ВЪЕЗЖАЮЩИМ В УКРАИНУ" tagline="Страховой полис, который покрывает военные риски">
        <iframe 
          src="https://visitukraine.today/iframeRU/ref/citybrokers?mode=to&product_id=62&category_product=war-risks-insurance&is_partner_iframe=true" 
              width="100%" 
              height="1147" 
              frameBorder="0"
        ></iframe>
        </HeadingSection>
      </div>
      <div className="row">
        <HeadingSection title="" tagline="">
        <iframe 
src="https://visitukraine.today/iframeRU/ref/citybrokers?mode=to&product_id=63&category_product=war-risks-insurance&is_partner_iframe=true" 
    width="100%" 
    height="1147" 
    frameBorder="0"
></iframe>
        </HeadingSection>
      </div>
      <div className="row">
        <HeadingSection title="" tagline="">
        <iframe 
src="https://visitukraine.today/iframeRU/ref/citybrokers?mode=to&product_id=90&category_product=war-risks-insurance&is_partner_iframe=true" 
    width="100%" 
    height="1147" 
    frameBorder="0"
></iframe>
        </HeadingSection>
      </div>
      <div className="row">
        <HeadingSection title="ЯК МИ ЦЕ РОБИМО" tagline="Заповніть форму-заявку на нашому сайті та забудьте про всі ризики!">
          <ContactForm/>
        </HeadingSection>
      </div>
    </div>
  </section>
);

export default WhoWeAreThree;
